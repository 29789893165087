import PropTypes from 'prop-types'
import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState
} from 'react'
import { useMutation } from 'react-query'
import { postCampaign, putCampaign } from 'api/sar/campaigns'
import { getConvertedImages } from 'util/images'
import { filterVarBoolean } from 'util/filters'

const SET_CAMPAIGN = 'SET_CAMPAIGN'
const SET_STEP = 'SET_STEP'
const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS'
const SET_OPEN_WISH_LIST_ITEMS = 'SET_OPEN_WISH_LIST_ITEMS'
const SET_OPEN_WISH_LIST_FILTERS = 'SET_OPEN_WISH_LIST_FILTERS'
const SET_OPEN_PRODUCT_DETAILS = 'SET_OPEN_PRODUCT_DETAILS'
const SET_REFRESH_WISH_LIST_FAVORITES = 'SET_REFRESH_WISH_LIST_FAVORITES'
const SET_OPEN_UNSAVED_CHANGES = 'SET_OPEN_UNSAVED_CHANGES'
const SET_PROMOTE_AS = 'SET_PROMOTE_AS'
const SET_OPEN_CAMPAIGN_STATUS_MODAL = 'SET_OPEN_CAMPAIGN_STATUS_MODAL'
const RELOAD_ACTIVE_CAMPAIGNS = 'RELOAD_ACTIVE_CAMPAIGNS'
const SET_CAMPAIGN_TO_CANCEL_IS_FEATURED = 'SET_CAMPAIGN_TO_CANCEL_IS_FEATURED'
const SET_STEPPER_BUTTON_CLICKED = 'SET_STEPPER_BUTTON_CLICKED'
const SET_OPEN_CREATE_MANUAL_WISH_LIST_MODAL =
  'SET_OPEN_CREATE_MANUAL_WISH_LIST_MODAL'
const SET_OPEN_CONFIRM_MANUAL_WISH_LIST_MODAL =
  'SET_OPEN_CONFIRM_MANUAL_WISH_LIST_MODAL'
const SET_CAMPAIGN_DRAFT_TO_DELETE = 'SET_CAMPAIGN_DRAFT_TO_DELETE'
const SET_DISPLAY_ERRORS_ON_DRAFT = 'SET_DISPLAY_ERRORS_ON_DRAFT'

const save = async (payload) => {
  try {
    const isNew = !payload.id
    const formData = new FormData()
    const includeImages = payload.includeImages || false

    if (!isNew) {
      formData.append('id', payload.id)
    }

    const excludedKeys = [
      'shipTo',
      'isValidShippingAddress',
      'openWishListItems',
      'openWishListFilters',
      'openProductDetails',
      'refreshWishListFavorites',
      'currentShippingAddress',
      'refreshWishListFavorites',
      'updatedFields',
      'isLegacy',
      'is_legacy',
      'unsavedChanges',
      'promoteAs',
      'openCampaignStatusModal',
      'reloadActiveCampaigns',
      'campaignCancelIsFeatured',
      'isPublished',
      'isClosed',
      'epoch',
      'editMode',
      'buttonClicked',
      'openCreateManualWishListModal',
      'openConfirmManualWishListModal',
      'draftId',
      'displayErrorsOnDraft',
      'uploading'
    ]

    if (!includeImages) {
      excludedKeys.push('images')
      excludedKeys.push('sortedImages')
    }

    for (const key in payload) {
      if (excludedKeys.includes(key)) {
        continue
      }

      if (key === 'productId') {
        continue
      }

      if (key !== 'id') {
        let value = payload[key]

        if (key === 'deletedImages') {
          for (let index = 0; index < value?.length; index++) {
            formData.append(`deletedImages[${index}]`, value[index])
          }
        } else if (
          key === 'images' &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          for (let index = 0; index < value?.length; index++) {
            const { id, isGraphic } = value[index]
            const { small, large } = await getConvertedImages(value[index])

            if (id !== null && Number.isInteger(Number(id))) {
              formData.append(`images[${index}][id]`, id)
            }

            formData.append(
              `images[${index}][is_graphic]`,
              isGraphic ? '1' : '0'
            )
            formData.append(`images[${index}][cover]`, index === 0 ? '1' : '0')
            formData.append(`images[${index}][media_order]`, index.toString())
            formData.append(`images[${index}][small]`, small)
            formData.append(`images[${index}][large]`, large)
          }
        } else if (
          key === 'sortedImages' &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          for (let index = 0; index < value?.length; index++) {
            const { id, isGraphic } = value[index]
            const { small, large } = await getConvertedImages(value[index])

            if (id && parseInt(id)) {
              formData.append(`images[${index}][id]`, id)
            }

            formData.append(
              `images[${index}][is_graphic]`,
              isGraphic ? '1' : '0'
            )
            formData.append(`images[${index}][cover]`, index === 0 ? '1' : '0')
            formData.append(`images[${index}][media_order]`, index.toString())
            formData.append(`images[${index}][small]`, small)
            formData.append(`images[${index}][large]`, large)
          }
        } else if (key === 'videos') {
          for (let index = 0; index < value?.length; index++) {
            const {
              url_thumbnail: urlThumbnail,
              video_mid: videoMid,
              video_url: videoUrl,
              id
            } = value[index]

            if (id && parseInt(id)) {
              formData.append(`videos[${index}][id]`, id)
            }

            formData.append(`videos[${index}][video_url]`, videoUrl)
            formData.append(`videos[${index}][video_mid]`, videoMid)
            formData.append(`videos[${index}][url_thumbnail]`, urlThumbnail)
          }
        } else if (key === 'wishListNeeds') {
          for (let index = 0; index < value?.length; index++) {
            formData.append(`wishListNeeds[${index}]`, value[index])
          }
        } else if (key === 'healthIssues') {
          for (let index = 0; index < value?.length; index++) {
            formData.append(`healthIssues[${index}]`, value[index])
          }
        } else if (
          ['featuredCat', 'weeklyWish', 'advertised', 'urgentNeeds'].includes(
            key
          )
        ) {
          formData.append(key, value ? '1' : '0')
        } else {
          if (key === 'amount' && value.length > 0 && value?.includes('$')) {
            value = value.replace('$', '')
          }

          formData.append(key, value || '')
        }
      }
    }

    if (isNew) {
      return await postCampaign(formData)
    }

    return await putCampaign(payload.id, formData)
  } catch (error) {
    return Promise.reject(error)
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CAMPAIGN:
      return {
        ...state,
        ...action.payload
      }
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      }
    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        productId: action.payload
      }
    case SET_OPEN_WISH_LIST_ITEMS:
      return {
        ...state,
        openWishListItems: action.payload
      }
    case SET_OPEN_WISH_LIST_FILTERS:
      return {
        ...state,
        openWishListFilters: action.payload
      }
    case SET_OPEN_PRODUCT_DETAILS:
      return {
        ...state,
        openProductDetails: action.payload
      }
    case SET_REFRESH_WISH_LIST_FAVORITES:
      return {
        ...state,
        refreshWishListFavorites: action.payload
      }
    case SET_OPEN_UNSAVED_CHANGES:
      return {
        ...state,
        unsavedChanges: action.payload
      }
    case SET_PROMOTE_AS:
      return {
        ...state,
        promoteAs: action.payload
      }
    case SET_OPEN_CAMPAIGN_STATUS_MODAL:
      return {
        ...state,
        openCampaignStatusModal: action.payload
      }
    case RELOAD_ACTIVE_CAMPAIGNS:
      return {
        ...state,
        reloadActiveCampaigns: action.payload
      }
    case SET_CAMPAIGN_TO_CANCEL_IS_FEATURED:
      return {
        ...state,
        campaignCancelIsFeatured: action.payload
      }
    case SET_STEPPER_BUTTON_CLICKED:
      return {
        ...state,
        buttonClicked: action.payload
      }
    case SET_OPEN_CREATE_MANUAL_WISH_LIST_MODAL:
      return {
        ...state,
        openCreateManualWishListModal: action.payload
      }
    case SET_OPEN_CONFIRM_MANUAL_WISH_LIST_MODAL:
      return {
        ...state,
        openConfirmManualWishListModal: action.payload
      }
    case SET_CAMPAIGN_DRAFT_TO_DELETE:
      return {
        ...state,
        draftId: action.payload
      }
    case SET_DISPLAY_ERRORS_ON_DRAFT:
      return {
        ...state,
        displayErrorsOnDraft: action.payload
      }
    default:
      return state
  }
}

const CampaignStepperContext = createContext(null)
const CAMPAIGN_BUILDER_LOCAL_STORAGE_KEY = 'campaignBuilder'

const INITIAL_STATE = {
  step: 1,
  title: '',
  story: '',
  type: '',
  amount: '',
  fundedAmount: 0,
  multipleAnimals: '',
  isSpecificMedicalCase: '',
  petName: '',
  isUSAnimal: '',
  location: '',
  shippingAddress: 0,
  isValidShippingAddress: false,
  images: [],
  videos: [],
  deletedImages: [],
  species: '',
  breed: '',
  age: '',
  sex: '',
  color: '',
  size: '',
  wishListNeeds: [],
  healthIssues: [],
  otherHealthIssues: '',
  productId: null,
  openWishListItems: false,
  openWishListFilters: false,
  openProductDetails: false,
  refreshWishListFavorites: false,
  currentShippingAddress: null,
  shipTo: null,
  unsavedChanges: false,
  isLegacy: false,
  featuredCat: false,
  weeklyWish: false,
  advertised: false,
  urgentNeeds: false,
  promoteAs: '',
  openCampaignStatusModal: false,
  isPublished: false,
  isClosed: false,
  reloadActiveCampaigns: false,
  campaignCancelIsFeatured: false,
  editMode: false,
  buttonClicked: false,
  openCreateManualWishListModal: false,
  openConfirmManualWishListModal: false,
  draftId: null,
  uploading: false,
  displayErrorsOnDraft: false
}

const CampaignStepperProvider = ({ children }) => {
  const mutation = useMutation(save)
  const { isLoading, isError, isSuccess, isIdle } = mutation
  const [apiError, setApiError] = useState(null)
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem(
      CAMPAIGN_BUILDER_LOCAL_STORAGE_KEY,
      JSON.stringify(state)
    )
  }, [state])

  useEffect(() => {
    const data = localStorage.getItem(CAMPAIGN_BUILDER_LOCAL_STORAGE_KEY)

    if (data) {
      try {
        const payload = JSON.parse(data)
        const booleanKeys = ['specificAnimal']

        booleanKeys.forEach((key) => {
          if (payload[key] !== undefined) {
            payload[key] = filterVarBoolean(payload[key])
          }
        })

        dispatch({ type: SET_CAMPAIGN, payload })
      } catch (error) {
        console.error('Error parsing stored state:', error)
      }
    }
  }, [])

  const saveCampaign = async (payload, includeImages = false) => {
    try {
      payload.includeImages = includeImages

      return await mutation.mutateAsync(payload)
    } catch (error) {
      setApiError(error)
      console.error('Error saving campaign:', error)
    }
  }

  const isAlreadyPublished = state.isPublished || state.isClosed
  const isDirtyCampaignState =
    JSON.stringify(state) !== JSON.stringify(INITIAL_STATE)

  const clearApiError = () => {
    setApiError(null)
  }

  return (
    <CampaignStepperContext.Provider
      value={{
        state,
        dispatch,
        saveCampaign,
        isLoading,
        isError,
        isSuccess,
        isIdle,
        apiError,
        clearApiError,
        isAlreadyPublished,
        isDirtyCampaignState
      }}
    >
      {children}
    </CampaignStepperContext.Provider>
  )
}

CampaignStepperProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useCampaignStepper = () => {
  const context = useContext(CampaignStepperContext)

  if (!context) {
    throw new Error('useCampaignStepper must be used within a StepperProvider')
  }

  return context
}

export {
  CampaignStepperProvider,
  useCampaignStepper,
  SET_CAMPAIGN,
  SET_STEP,
  SET_PRODUCT_DETAILS,
  SET_OPEN_WISH_LIST_ITEMS,
  SET_OPEN_WISH_LIST_FILTERS,
  SET_OPEN_PRODUCT_DETAILS,
  SET_REFRESH_WISH_LIST_FAVORITES,
  CAMPAIGN_BUILDER_LOCAL_STORAGE_KEY,
  SET_OPEN_UNSAVED_CHANGES,
  SET_PROMOTE_AS,
  SET_OPEN_CAMPAIGN_STATUS_MODAL,
  RELOAD_ACTIVE_CAMPAIGNS,
  SET_CAMPAIGN_TO_CANCEL_IS_FEATURED,
  SET_STEPPER_BUTTON_CLICKED,
  SET_OPEN_CREATE_MANUAL_WISH_LIST_MODAL,
  SET_OPEN_CONFIRM_MANUAL_WISH_LIST_MODAL,
  SET_CAMPAIGN_DRAFT_TO_DELETE,
  SET_DISPLAY_ERRORS_ON_DRAFT
}
